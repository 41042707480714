<template>
  <div class="container">
    <div class="header">
      <h1 class="rainbow-text">Платишкин</h1>
      <p>
        Ваш надежный помощник в продаже товаров через Telegram. Легко. Быстро.
        Эффективно.
      </p>
    </div>
    <div class="features">
      <div class="feature">
        <h2>Легкость</h2>
        <p>
          Продавайте товары легко с помощью Telegram бота, интегрированного с
          вашей платежной системой
        </p>
      </div>
      <div class="feature">
        <h2>Быстрота</h2>
        <p>
          Ваши клиенты могут быстро и без лишних усилий покупать товары прямо в
          Telegram
        </p>
      </div>
      <div class="feature">
        <h2>Эффективность</h2>
        <p>
          Наш бот оптимизирует процесс продажи, позволяя вам сконцентрироваться
          на развитии бизнеса
        </p>
      </div>
      <div class="feature">
        <h2>Поддерживаем ЮКасса</h2>
        <p>
          С нами вы можете интегрироваться с популярной платежной системой
          ЮКасса
        </p>
      </div>
      <div class="feature">
        <h2>Гарантируем легкую настройку</h2>
        <p>
          Настройте нашего бота за считанные минуты и начните продавать сразу
        </p>
      </div>
      <div class="feature">
        <h2>Онлайн поддержка 24/7</h2>
        <p>Наша команда поддержки готова помочь вам в любое время суток</p>
      </div>
    </div>
    <div class="contact-info">
      <div class="email-wrapper">
        <i class="fas fa-envelope"></i>
        <span
          >Контактная почта:
          <a href="mailto:support@platishkin.ru">support@platishkin.ru</a></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default { name: "MainPage" };
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background: #0d0d0d; /* фон */
  color: #e0e0e0; /* текст */
  text-align: center;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.header {
  animation: fadeInDown 0.5s ease-in-out;
  margin-bottom: 30px;
}

.header h1 {
  font-size: 4rem;
  margin: 0;
  color: transparent;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
}

.header p {
  font-size: 1.4rem;
  margin: 20px 0;
  color: #b0b0b0;
  animation: fadeInUp 0.7s ease-in-out;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

.feature {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: scale(1.05);
}

.feature h2 {
  font-size: 1.5rem;
  color: #4a90e2;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1rem;
  color: #b0b0b0;
}

.email-wrapper {
  display: inline-flex;
  align-items: center;
  background: #1a1a1a;
  border: 2px solid #4a90e2;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.email-wrapper .fa-envelope {
  font-size: 24px;
  margin-right: 10px;
}

.contact-info {
  font-size: 1rem;
  color: #e0e0e0;
  animation: fadeIn 1.2s ease-in-out;
  margin-top: 40px;
  margin-bottom: 15px;
}

a {
  color: #4a90e2;
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .feature {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .feature {
    width: calc(100% - 40px);
  }
}

.rainbow-text {
  background: linear-gradient(
    to right,
    #ff0000,
    #ff8000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #8000ff,
    #ff0000
  );
  background-size: 200% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: rainbow-text-animation 4s linear infinite;
}

@keyframes rainbow-text-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>
