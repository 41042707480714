<template>
  <div
    :style="{ backgroundImage: `url(${storeItems.background_img})` }"
    class="store-items"
  >
    <div class="container">
      <template v-if="storeItems.id">
        <header class="store-header">
          <img :src="storeItems.logo_img" alt="Store Logo" class="store-logo" />
          <h1>{{ storeItems.store_name }}</h1>
        </header>
        <div class="inner">
          <div v-for="item in storeItems.products" :key="item.id" class="item">
            <!-- Условный рендеринг элемента изображения -->
            <img
              v-if="item.main_photo_url"
              :src="item.main_photo_url"
              alt="Product Image"
              class="item-image"
            />
            <h2>{{ item.name }}</h2>
            <p v-if="item.short_description" class="short-description">
              {{ item.short_description }}
            </p>
            <div class="price">
              <span v-if="item.discount > 0" class="discount">
                {{ item.discount }}% скидка
              </span>
              <span v-if="item.discount > 0" class="original-price">
                {{ item.price }} ₽
              </span>
              <span class="final-price">
                {{ discountedPrice(item.price, item.discount) }} ₽
              </span>
            </div>
            <div
              v-if="isItemAdded(item.id) && item.max_value > 1"
              class="quantity-controls"
            >
              <button
                :disabled="getQuantity(item) === 1"
                class="quantity-btn"
                @click="decreaseQuantity(item)"
              >
                −
              </button>
              <span class="quantity">{{ getQuantity(item) }}</span>
              <button
                :disabled="
                  getQuantity(item) === item.max_value && item.max_value > 0
                "
                class="quantity-btn"
                @click="increaseQuantity(item)"
              >
                +
              </button>
            </div>
            <button
              :class="{
                'btn-add': !isItemAdded(item.id) && item.max_value !== 0,
                'btn-remove': isItemAdded(item.id),
                'btn-disabled': item.max_value === 0,
              }"
              :disabled="item.max_value === 0"
              class="btn"
              @click="toggleItemInCart(item)"
            >
              {{
                item.max_value === 0
                  ? "Нет в наличии"
                  : isItemAdded(item.id)
                  ? "Удалить"
                  : "Добавить"
              }}
            </button>
          </div>
        </div>
        <router-link
          :class="{ 'btn-pay-disabled': selectedItems.length === 0 }"
          :disabled="selectedItems.length === 0"
          :to="{ name: 'PayPage', params: { id: id } }"
          class="btn btn-pay"
        >
          Оплатить
        </router-link>
      </template>
      <template v-else>
        <h1>Магазин не найден</h1>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useItemsStore } from "@/assets/store";

export default {
  name: "StoreItems",
  props: ["id"],
  data() {
    return {
      storeItems: {
        id: "",
        store_name: "",
        products: [],
        background_img: "",
        logo_img: "",
      },
    };
  },
  computed: {
    selectedItems() {
      const store = useItemsStore();
      return store.selectedItems;
    },
  },
  methods: {
    async fetchStoreItems() {
      try {
        const response = await axios.get(`/api/v1/store/${this.id}`);
        this.storeItems = response.data;
      } catch (error) {
        console.error("Error fetching store items:", error);
      }
    },
    toggleItemInCart(item) {
      const store = useItemsStore();
      if (this.isItemAdded(item.id)) {
        store.removeItem(item.id);
      } else {
        store.addItem({ ...item, quantity: 1 });
      }
    },
    isItemAdded(itemId) {
      return this.selectedItems.some((item) => item.id === itemId);
    },
    increaseQuantity(item) {
      const store = useItemsStore();
      const cartItem = store.selectedItems.find((i) => i.id === item.id);
      if (
        cartItem &&
        (cartItem.quantity < item.max_value || item.max_value === 0)
      ) {
        cartItem.quantity++;
      }
    },
    decreaseQuantity(item) {
      const store = useItemsStore();
      const cartItem = store.selectedItems.find((i) => i.id === item.id);
      if (cartItem && cartItem.quantity > 1) {
        cartItem.quantity--;
      }
    },
    getQuantity(item) {
      const store = useItemsStore();
      const cartItem = store.selectedItems.find((i) => i.id === item.id);
      return cartItem ? cartItem.quantity : 1;
    },
    discountedPrice(price, discount) {
      const originalPrice = parseFloat(price);
      const finalPrice = originalPrice - originalPrice * (discount / 100);
      return finalPrice.toFixed(2);
    },
  },
  mounted() {
    this.fetchStoreItems();
  },
};
</script>

<style scoped>
.store-items {
  margin: 0;
  padding: 20px;
  font-size: 18px;
  color: var(--tg-theme-text-color);
  background: var(--tg-theme-bg-color);
  background-size: cover;
  background-position: center;
  padding-bottom: 80px; /* Добавляем отступ снизу, чтобы контент был видимым */
}

.store-items .container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.store-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.store-logo {
  max-width: 100px;
  margin-right: 20px;
}

.store-header h1 {
  font-size: 24px;
  color: #333;
}

.inner {
  display: grid;
  gap: 20px;
}

.item {
  position: relative;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  text-align: center;
  transition: transform 0.2s;
}

.item-image {
  width: 100%; /* Изображение по ширине карточки */
  height: auto; /* Автоматическая высота для сохранения пропорций */
  border-radius: 8px;
  margin-bottom: 10px;
}

.item h2,
.item p {
  color: #333;
}

.short-description {
  font-size: 14px;
  color: #777;
}

.item:hover {
  transform: translateY(-5px);
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.discount {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #ff9800;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  transform: rotate(-15deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-bottom: 5px;
}

.final-price {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.quantity-btn {
  border: 1px solid #777;
  background-color: #444;
  width: 50px;
  color: #fff;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
}

.quantity {
  color: #000;
  margin: 0 10px;
  font-size: 14px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  transition: background 0.2s linear;
}

.btn-add {
  background: #4caf50;
}

.btn-add:hover {
  background: #45a049;
}

.btn-remove {
  background: #f44336;
}

.btn-remove:hover {
  background: #e53935;
}

.btn-disabled {
  background: #cccccc; /* Серый цвет для кнопки "Нет в наличии" */
  cursor: not-allowed;
}

.btn-pay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background: #2196f3;
  text-align: center; /* Выровнять текст по центру */
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: background 0.2s linear;
  z-index: 1000;
}

.btn-pay-disabled {
  background: #cccccc; /* Серый цвет для неактивной кнопки */
  cursor: not-allowed;
}

.btn-pay:hover {
  background: #1e88e5;
}

.btn-pay-disabled:hover {
  background: #cccccc; /* Отключение изменения фона при наведении */
}
</style>
